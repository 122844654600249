import React from 'react';
import Script from 'next/script';

// Define script rendering strategies
type ScriptStrategy = 'server' | 'afterInteractive' | 'lazyOnload' | 'worker';

// Define meta tag interface for og tags and other meta tags
interface MetaTag {
  id: string;
  property?: string;
  name?: string;
  content: string;
}

// Define script types
interface ScriptItem {
  id: string;
  content?: string;
  src?: string;
  type?: string;
  async?: boolean;
  defer?: boolean;
  position?: 'head' | 'body';
  strategy?: ScriptStrategy; // Add strategy field
}

// Global registry for scripts and meta tags
let headScripts: ScriptItem[] = [];
let bodyScripts: ScriptItem[] = [];
let clientScripts: ScriptItem[] = []; // New array for client-side scripts
let metaTags: MetaTag[] = []; // New array for meta tags

// Reset the registry (useful between requests)
export const resetScriptRegistry = () => {
  headScripts = [];
  bodyScripts = [];
  clientScripts = [];
  metaTags = [];
};

// Register scripts for the current page
export const registerSeoScripts = (scripts: ScriptItem[]) => {
  scripts.forEach(script => {
    // Client-side scripts with Next.js Script component
    if (script.strategy && script.strategy !== 'server') {
      clientScripts.push(script);
      return;
    }
    
    // Server-side scripts with raw script tags
    if (script.position === 'body') {
      bodyScripts.push(script);
    } else {
      headScripts.push(script);
    }
  });
};

// Register meta tags for the current page
export const registerSeoMetaTags = (tags: MetaTag[]) => {
  metaTags = [...metaTags, ...tags];
};

// Render scripts in Head component (server-side)
const HeadScripts = () => {
  return (
    <>
      {headScripts.map((script) => (
        <script
          key={script.id}
          id={script.id}
          type={script.type || 'text/javascript'}
          src={script.src}
          async={script.async}
          defer={script.defer}
          dangerouslySetInnerHTML={script.content ? { __html: script.content } : undefined}
        />
      ))}
    </>
  );
};

// Render meta tags in Head component
const MetaTags = () => {
  return (
    <>
      {metaTags.map((tag) => (
        <meta
          key={tag.id}
          property={tag.property}
          name={tag.name}
          content={tag.content}
        />
      ))}
    </>
  );
};

// Render scripts in body (server-side)
const BodyScripts = () => {
  return (
    <>
      {bodyScripts.map((script) => (
        <script
          key={script.id}
          id={script.id}
          type={script.type || 'text/javascript'}
          src={script.src}
          async={script.async}
          defer={script.defer}
          dangerouslySetInnerHTML={script.content ? { __html: script.content } : undefined}
        />
      ))}
    </>
  );
};

// Client-side scripts using Next.js Script component
const ClientScripts = () => {
  return (
    <>
      {clientScripts.map((script) => (
        <Script
          key={script.id}
          id={script.id}
          strategy={script.strategy as 'afterInteractive' | 'lazyOnload' | 'worker'}
          src={script.src}
          dangerouslySetInnerHTML={script.content ? { __html: script.content } : undefined}
        />
      ))}
    </>
  );
};

// Export components and functions
export const SeoScriptRegistry = {
  Head: HeadScripts,
  Meta: MetaTags,
  Body: BodyScripts,
  Client: ClientScripts,
  register: registerSeoScripts,
  registerMeta: registerSeoMetaTags,
  reset: resetScriptRegistry
}; 