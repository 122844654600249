import '@/styles/globals.css';
import { AppProps } from 'next/app';
import { processSeoDataForRegistry } from '@/lib/seoHelpers';
import { SeoScriptRegistry } from '@/lib/seoScriptRegistry';
import Head from 'next/head';

function App({ Component, pageProps }: AppProps) {
  // Process SEO data on the server side
  if (typeof window === 'undefined') {
    // Always reset registry between requests
    if (pageProps.page?.seo) {
      processSeoDataForRegistry(pageProps.page.seo, pageProps.page, pageProps.page.version === 'staging');
    }
  }
  
  // Get the default page title from page data
  const defaultTitle = pageProps.page?.title || '';
  const isStaging = pageProps.page?.version === 'staging';

  // Initialize SEO values
  let metaTitle = isStaging ? '(Staging Version) ' + defaultTitle : defaultTitle;
  let metaDescription = '';

  // Extract SEO values from entries if available
  if (pageProps.page?.seo && Array.isArray(pageProps.page.seo)) {
    for (const seoItem of pageProps.page.seo) {
      if (!seoItem.seo_collection_id?.entries || !Array.isArray(seoItem.seo_collection_id?.entries)) {
        continue;
      }
      
      // Search through all entries for both title and description
      for (const entry of seoItem.seo_collection_id.entries) {
        if (!entry.seo_entries_id) continue;
        
        // Handle SEO title
        if (entry.seo_entries_id.type === 'title' || entry.seo_entries_id.type === 'page_title') {
          let titleContent = entry.seo_entries_id.entry || '';
          // Replace any {title} variable with the actual page title
          titleContent = titleContent.replace(/{title}/g, defaultTitle);
          metaTitle = isStaging ? '(Staging Version) ' + titleContent : titleContent;
        }
        
        // Handle SEO description
        if (entry.seo_entries_id.type === 'description') {
          metaDescription = entry.seo_entries_id.entry || '';
          // Replace any {title} variable in description
          metaDescription = metaDescription.replace(/{title}/g, defaultTitle);
        }
      }
    }
  }

  return (
    <>
      <Head>
        {metaTitle && <title>{metaTitle}</title>}
        {metaDescription && <meta name="description" content={metaDescription} />}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      
      {/* Client-side scripts using Next.js Script component */}
      <SeoScriptRegistry.Client />
      
      <Component {...pageProps} />
    </>
  );
}

export default App;
