import { registerSeoScripts, resetScriptRegistry, registerSeoMetaTags } from './seoScriptRegistry';

// Define script rendering strategies
type ScriptStrategy = 'server' | 'afterInteractive' | 'lazyOnload' | 'worker';

// Define meta tag interface for og tags and other meta tags
interface MetaTag {   
  id?: string;
  property?: string;
  name?: string;
  content: string;
}

// Define the script item interface to match the one in seoScriptRegistry
interface ScriptItem {
  id: string;
  content?: string;
  src?: string;
  type?: string;
  async?: boolean;
  defer?: boolean;
  position?: 'head' | 'body';
  strategy?: ScriptStrategy;
}

// Enhanced OpenGraph interface with more fields
export interface OpenGraphData {
  // Basic OG properties
  locale?: string;
  title?: string;
  description?: string;
  url?: string;
  image?: string;
  imageSecureUrl?: string;
  type?: string;
  siteName?: string;
  
  // Article specific properties
  articlePublishedTime?: string;
  articleModifiedTime?: string;
  articlePublisher?: string;
  articleAuthor?: string;
  articleSection?: string;
  articleTags?: string[];
  
  // Twitter specific properties
  twitterCard?: string;
  twitterSite?: string;
  twitterTitle?: string;
  twitterDescription?: string;
  twitterCreator?: string;
  twitterImage?: string;
}

// Helper to generate all meta tags (OG, Twitter, Article) in batches
export const generateOpenGraphTags = (data: OpenGraphData): MetaTag[] => {
  const tags: MetaTag[] = [];
  const id = 'generated';
  
  // Basic OG tags
  if (data.locale) {
    tags.push({
      id: `${id}-og-locale`,
      property: 'og:locale',
      content: data.locale
    });
  }
  
  if (data.title) {
    tags.push({
      id: `${id}-og-title`,
      property: 'og:title',
      content: data.title
    });
  }
  
  if (data.description) {
    tags.push({
      id: `${id}-og-description`,
      property: 'og:description',
      content: data.description
    });
  }
  
  if (data.image) {
    tags.push({
      id: `${id}-og-image`,
      property: 'og:image',
      content: data.image
    });
    
    if (data.imageSecureUrl) {
      tags.push({
        id: `${id}-og-image-secure-url`,
        property: 'og:image:secure_url',
        content: data.imageSecureUrl
      });
    } else if (data.image.startsWith('https://')) {
      tags.push({
        id: `${id}-og-image-secure-url`,
        property: 'og:image:secure_url',
        content: data.image
      });
    }
  }
  
  if (data.url) {
    tags.push({
      id: `${id}-og-url`,
      property: 'og:url',
      content: data.url
    });
  }
  
  if (data.type) {
    tags.push({
      id: `${id}-og-type`,
      property: 'og:type',
      content: data.type
    });
  } else {
    tags.push({
      id: `${id}-og-type`,
      property: 'og:type',
      content: 'website'
    });
  }
  
  if (data.siteName) {
    tags.push({
      id: `${id}-og-site-name`,
      property: 'og:site_name',
      content: data.siteName
    });
  }
  
  // Article specific tags
  if (data.type === 'article' || data.articlePublishedTime || data.articleModifiedTime || data.articlePublisher) {
    if (data.articlePublishedTime) {
      tags.push({
        id: `${id}-article-published-time`,
        property: 'article:published_time',
        content: data.articlePublishedTime
      });
    }
    
    if (data.articleModifiedTime) {
      tags.push({
        id: `${id}-article-modified-time`,
        property: 'article:modified_time',
        content: data.articleModifiedTime
      });
    }
    
    if (data.articlePublisher) {
      tags.push({
        id: `${id}-article-publisher`,
        property: 'article:publisher',
        content: data.articlePublisher
      });
    }
    
    if (data.articleAuthor) {
      tags.push({
        id: `${id}-article-author`,
        property: 'article:author',
        content: data.articleAuthor
      });
    }
    
    if (data.articleSection) {
      tags.push({
        id: `${id}-article-section`,
        property: 'article:section',
        content: data.articleSection
      });
    }
    
    if (data.articleTags && data.articleTags.length > 0) {
      data.articleTags.forEach((tag, index) => {
        tags.push({
          id: `${id}-article-tag-${index}`,
          property: 'article:tag',
          content: tag
        });
      });
    }
  }
  
  // Twitter Card tags
  if (data.twitterCard) {
    tags.push({
      id: `${id}-twitter-card`,
      name: 'twitter:card',
      content: data.twitterCard
    });
  }
  
  if (data.twitterSite) {
    tags.push({
      id: `${id}-twitter-site`,
      name: 'twitter:site',
      content: data.twitterSite
    });
  }
  
  if (data.twitterTitle) {
    tags.push({
      id: `${id}-twitter-title`,
      name: 'twitter:title',
      content: data.twitterTitle
    });
  }
  
  if (data.twitterDescription) {
    tags.push({
      id: `${id}-twitter-description`,
      name: 'twitter:description',
      content: data.twitterDescription
    });
  }
  
  if (data.twitterCreator) {
    tags.push({
      id: `${id}-twitter-creator`,
      name: 'twitter:creator',
      content: data.twitterCreator
    });
  }
  
  if (data.twitterImage) {
    tags.push({
      id: `${id}-twitter-image`,
      name: 'twitter:image',
      content: data.twitterImage || data.image || ''
    });
  }
  
  return tags;
};

// Register OpenGraph tags directly
export const registerOpenGraph = (data: OpenGraphData): void => {
  const tags = generateOpenGraphTags(data);
  // Filter out tags without an id or ensure each tag has an id
  const validTags = tags.map(tag => tag.id ? tag : { ...tag, id: `og-${Math.random().toString(36).substr(2, 9)}` });
  registerSeoMetaTags(validTags as any);
};

// Function to replace template variables in OpenGraph data
export const processTemplateVariables = (ogData: OpenGraphData, pageData: any, seoData: any[] = []): OpenGraphData => {
  if (!pageData) return ogData;
  
  // Create a deep copy to avoid modifying the original
  const processedData = JSON.parse(JSON.stringify(ogData));
  
  // Process each field in the OpenGraph data
  Object.entries(processedData).forEach(([key, value]) => {
    if (typeof value === 'string') {
      // Replace template variables like {title} with values from pageData
      processedData[key as keyof OpenGraphData] = value.replace(
        /{([^{}]+)}/g,
        (match, variable) => {
          // Check if it's a SEO type variable (e.g., {seo.description})
          if (variable.startsWith('seo.')) {
            const seoType = variable.substring(4); // Extract the type (e.g., "description")
            
            // Look for a SEO entry with the specified type
            for (const seoItem of seoData) {
              if (!seoItem.seo_collection_id?.entries || !Array.isArray(seoItem.seo_collection_id?.entries)) {
                continue;
              }
              
              // Search through all entries
              for (const entry of seoItem.seo_collection_id.entries) {
                if (entry.seo_entries_id && entry.seo_entries_id.type === seoType) {
                  return entry.seo_entries_id.entry || match;
                }
              }
            }
            
            // If no matching SEO entry is found, return the original match
            return match;
          }
          
          // Default case: try to get the value from pageData
          return pageData[variable] || match;
        }
      );
    }
  });
  
  return processedData;
};

export const processSeoDataForRegistry = (seoData: any, pageData: any = null, isStaging: boolean = false) => {
  // Reset registry to ensure no scripts from previous requests remain
  resetScriptRegistry();
  
  if (!seoData || !Array.isArray(seoData) || seoData.length === 0) {
    return;
  }

  const scripts: ScriptItem[] = [];
  const metaTags: MetaTag[] = [];
  let title = '';
  let ogData: OpenGraphData = {};

  // Process each SEO collection
  seoData.forEach((seoItem, indexMain) => {
    if (!seoItem.seo_collection_id?.entries || !Array.isArray(seoItem.seo_collection_id?.entries)) {
      return;
    }

    seoItem.seo_collection_id.entries.forEach((entry, index) => {
      if (!entry.seo_entries_id) return;
      
      const scriptId = `${indexMain}-${index}`;
      
      // Process schema scripts - these should be server-side for SEO
      if (entry.seo_entries_id.type === 'schema') {
        scripts.push({
          id: scriptId,
          type: 'application/ld+json',
          content: entry.seo_entries_id.entry || '',
          position: 'head',
          strategy: 'server' // Explicitly server-side
        });
      }
      
      // Store the title (we don't register it as a script, but save for reference)
      if (entry.seo_entries_id.type === 'title') {
        title = entry.seo_entries_id.entry || '';
        ogData.title = title; // Use title for OG data as well
      }
      
      // Process Open Graph batch
      if (entry.seo_entries_id.type === 'og') {
        try {
          const ogBatch = JSON.parse(entry.seo_entries_id.entry || '{}');

          
          // Merge with existing OG data
          ogData = {
            ...ogData,
            ...ogBatch,
            // Map specific fields if they exist in the batch
            locale: ogBatch.locale || ogData.locale,
            title: ogBatch.title || ogData.title,
            description: ogBatch.description || ogData.description,
            url: ogBatch.url || ogData.url,
            image: ogBatch.image || ogData.image,
            imageSecureUrl: ogBatch.image_secure_url || ogBatch.imageSecureUrl || ogData.imageSecureUrl,
            type: ogBatch.type || ogData.type,
            siteName: ogBatch.site_name || ogBatch.siteName || ogData.siteName,
          };
        } catch (e) {
          console.error('Error parsing OG batch data:', e);
        }
      }
      
      // Process Article batch
      if (entry.seo_entries_id.type === 'article') {
        try {
          const articleBatch = JSON.parse(entry.seo_entries_id.entry || '{}');
          
          // Merge with existing OG data
          ogData = {
            ...ogData,
            ...articleBatch,
            // Map specific fields if they exist in the batch
            articlePublishedTime: articleBatch.published_time || articleBatch.articlePublishedTime || ogData.articlePublishedTime,
            articleModifiedTime: articleBatch.modified_time || articleBatch.articleModifiedTime || ogData.articleModifiedTime,
            articlePublisher: articleBatch.publisher || articleBatch.articlePublisher || ogData.articlePublisher,
            articleAuthor: articleBatch.author || articleBatch.articleAuthor || ogData.articleAuthor,
            articleSection: articleBatch.section || articleBatch.articleSection || ogData.articleSection,
            articleTags: articleBatch.tags || articleBatch.articleTags || ogData.articleTags,
          };
        } catch (e) {
          console.error('Error parsing Article batch data:', e);
        }
      }
      
      // Process Twitter batch
      if (entry.seo_entries_id.type === 'twitter') {
        try {
          const twitterBatch = JSON.parse(entry.seo_entries_id.entry || '{}');
          
          // Merge with existing OG data
          ogData = {
            ...ogData,
            ...twitterBatch,
            // Map specific fields if they exist in the batch
            twitterCard: twitterBatch.card || twitterBatch.twitterCard || ogData.twitterCard,
            twitterSite: twitterBatch.site || twitterBatch.twitterSite || ogData.twitterSite,
            twitterTitle: twitterBatch.title || twitterBatch.twitterTitle || ogData.twitterTitle,
            twitterDescription: twitterBatch.description || twitterBatch.twitterDescription || ogData.twitterDescription,
            twitterCreator: twitterBatch.creator || twitterBatch.twitterCreator || ogData.twitterCreator,
            twitterImage: twitterBatch.image || twitterBatch.twitterImage || ogData.twitterImage,
          };
        } catch (e) {
          console.error('Error parsing Twitter batch data:', e);
        }
      }
      
      // Process individual OG tags (for backward compatibility)
      if (entry.seo_entries_id.type === 'og:title') {
        ogData.title = entry.seo_entries_id.entry || ogData.title || '';
      }
      
      if (entry.seo_entries_id.type === 'og:description') {
        ogData.description = entry.seo_entries_id.entry || ogData.description || '';
      }
      
      if (entry.seo_entries_id.type === 'og:image') {
        ogData.image = entry.seo_entries_id.entry || ogData.image || '';
      }
      
      if (entry.seo_entries_id.type === 'og:url') {
        ogData.url = entry.seo_entries_id.entry || ogData.url || '';
      }
      
      if (entry.seo_entries_id.type === 'og:type') {
        ogData.type = entry.seo_entries_id.entry || ogData.type || 'website';
      }
      
      if (entry.seo_entries_id.type === 'og:site_name') {
        ogData.siteName = entry.seo_entries_id.entry || ogData.siteName || '';
      }
      
      // Process individual Twitter tags (for backward compatibility)
      if (entry.seo_entries_id.type === 'twitter:card') {
        ogData.twitterCard = entry.seo_entries_id.entry || ogData.twitterCard || 'summary_large_image';
      }
      
      if (entry.seo_entries_id.type === 'twitter:site') {
        ogData.twitterSite = entry.seo_entries_id.entry || ogData.twitterSite || '';
      }
      
      if (entry.seo_entries_id.type === 'twitter:creator') {
        ogData.twitterCreator = entry.seo_entries_id.entry || ogData.twitterCreator || '';
      }
      
      // Google Tag Manager - server-side for better performance
      if (entry.seo_entries_id.type === 'google-tag-manager') {
        scripts.push({
          id: `${scriptId}-gtm`,
          content: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${entry.seo_entries_id.entry}');
          `,
          position: 'head',
          strategy: 'server'
        });
      }
      
      // Google Analytics - can be client-side with afterInteractive strategy
      if (entry.seo_entries_id.type === 'google-tag') {
        // GA script source - client-side loading with afterInteractive
        scripts.push({
          id: `${scriptId}-1`,
          src: `https://www.googletagmanager.com/gtag/js?id=${entry.seo_entries_id.entry}`,
          async: true,
          strategy: 'lazyOnload' // Client-side strategy
        });
        
        // GA initialization script
        scripts.push({
          id: `${scriptId}-2`,
          content: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config','${entry.seo_entries_id.entry}');
          `,
          strategy: 'lazyOnload' // Client-side strategy
        });
      }
      
      // WhatConverts - can be client-side with lazyOnload for better performance
      if (entry.seo_entries_id.type === 'whatconverts') {
        scripts.push({
          id: `${scriptId}-2`,
          content: `
            var $wc_load=function(a){return JSON.parse(JSON.stringify(a))},$wc_leads=$wc_leads||{doc:{url:$wc_load(document.URL),ref:$wc_load(document.referrer),search:$wc_load(location.search),hash:$wc_load(location.hash)}};
          `,
          strategy: 'lazyOnload' // Client-side with lazy loading
        });
        
        scripts.push({
          id: `${scriptId}-1`,
          src: entry.seo_entries_id.entry,
          async: true,
          strategy: 'lazyOnload' // Client-side with lazy loading
        });
      }
    });
  });

  // Process template variables in the collected OG data
  if (Object.keys(ogData).length > 0 && pageData) {
    ogData = processTemplateVariables(ogData, pageData, seoData);
  }

  // Generate meta tags from collected OG data
  if (Object.keys(ogData).length > 0) {
    const ogTags = generateOpenGraphTags(ogData);
    metaTags.push(...ogTags);
  }

  // Register the collected scripts with our registry
  registerSeoScripts(scripts);
  
  // Register meta tags with our registry
  if (metaTags.length > 0) {
    // Filter out tags without an id or ensure each tag has an id
    const validMetaTags = metaTags.map(tag => tag.id ? tag : { ...tag, id: `meta-${Math.random().toString(36).substr(2, 9)}` });
    registerSeoMetaTags(validMetaTags as any);
  }
  
  return title;
}; 